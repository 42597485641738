<template>
  <div>
    <el-header
      id="el-header"
      height="132px"
      :style="{ position: position ? position : 'fixed' }"
    >
      <div class="postion-box">
        <div class="header-box">
          <div class="header-box-left">
            <div @mouseenter="Cityshow = true" @mouseleave="Cityshow = false">
              <div v-if="city">
                <i class="el-icon-location"></i>
                <span>{{ city }}</span>
              </div>
              <city-cascade
                @getCity="getCity"
                v-if="Cityshow"
                :style="{ position: 'absolute', top: '30px' }"
              ></city-cascade>
            </div>
            <div>您好，欢迎来到园艺联盟</div>
            <div class="header-box-login" @click="toLogin" v-if="!userinfo">
              您好，请登录
            </div>
            <div
              class="header-box-login"
              v-else
              v-text="`欢迎您,${userinfo.phone}`"
            ></div>
            <div class="header-box-register" v-if="!this.$store.state.token">
              免费注册
            </div>
            <div class="header-box-list" @click="myOrder">我的订单</div>
            <div class="header-box-list" @click="purchaseOrder">采购订单</div>
            <div
              class="header-box-list"
              @click="$router.push('/browsingHistory')"
            >
              我的足迹
            </div>
            <!-- <div
              class="header-box-list"
              @click="IncUpdate"
              v-if="roleId == 2 || roleId == 4"
            >
              查看授权
            </div> -->
            <!-- <div
              class="header-box-list"
              @click="applyFor"
              v-if="roleId == 2 || roleId == 4"
            >
              查看申请
            </div> -->
          </div>
        </div>
        <!-- 搜索框头组件 -->
        <div class="header-search">
          <!-- logo -->
          <div class="avater-logo">
            <router-link to="/index">
              <img class="avater-logo" src="../assets//images/timg.jpg" />
            </router-link>
          </div>
          <!-- logo -->
          <!-- 搜索 -->
          <div class="search-box">
            <div class="search-box-main">
              <div class="search-input">
                <input v-model="name" class="input" type="text" />
                <div @click="goGoodList" class="go-search">
                  <img
                    class="search-icon"
                    src="../assets/images/gosearch.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 搜索结束 -->
          <!-- 购物清单 -->
          <div class="shop-list" @click="shopList">
            <span class="iconfont">&#xe7b7;</span>
            <p>采购清单</p>
            
          </div>
          <!-- 购物车 -->
          <div class="shop-car" @click="goCar">
            <span class="iconfont">&#xe7b7;</span>
            <p>购物车</p>
            <div class="shop-cara">
              <p class="shopcar-index">{{ this.$store.state.shopnum }}</p>
            </div>
          </div>
          <!-- 购物车结束 -->
          <div class="talk" @click="$router.push('/chatRoom')">
            <span><i class="el-icon-chat-dot-round"></i>消息中心</span>
          </div>
          <!-- 搜索框头组件结束 -->
        </div>
      </div>
    </el-header>
  </div>
</template>
<script>
import cityCascade from "./cascade/cityCascade";
export default {
  components: { cityCascade },
  props: ["position"],
  data() {
    return {
      // 用户信息
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      name: "",
      // 地区选择显示隐藏
      Cityshow: false,
      // 当前选择城市
      city: JSON.parse(sessionStorage.getItem("checked"))[1],
    };
  },
  watch: {
    "$store.state.checked"(val) {
      sessionStorage.setItem("checked", val);
      if (JSON.parse(sessionStorage.getItem("checked"))) {
        this.city = JSON.parse(sessionStorage.getItem("checked"))[1];
      }
    },
  },
  activated() {
    this.$store.commit("updatechecked", sessionStorage.getItem("checked"));
  },
  computed: {
    roleId() {
      return sessionStorage.getItem("roleId");
    },
   
  },
  methods: {
    // 获取选择城市模块中的信息
    getCity(val, lable, bool) {
      console.log('---'+val,"===="+ lable, bool);
      // 隐藏级联选择框
      this.Cityshow = false;
      // 显示状态为选中城市
      this.city = lable[1];
      sessionStorage.setItem("checked", JSON.stringify(lable));
      sessionStorage.setItem("addressProvince", JSON.stringify(val[0]));
      sessionStorage.setItem("addressCity", JSON.stringify(val[1]));
      this.$store.commit("updatechecked", sessionStorage.getItem("checked"));
      location.reload();
    },
    // 退出登录
    async logOut() {
      const res = await this.$http({
        method: "post",
        url: "front/user/logout",
      });
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userId");
      this.userinfo = "";
    },
    // 获取购物车信息
    async getshopnum() {
      // 如果未登录,不请求
      if (sessionStorage.getItem("roleId") != "null") {
        const res = await this.$http({
          method: "get",
          url: "front/cart/findList?type=1",
        });
        if (res.data.data) {
          this.$store.commit(
            "altershopnum",
            res.data.data.cartProductVoList.length
          );
        }
      }
    },
    async goGoodList() {
      const res = await this.$http({
        method: "get",
        url: "front/product/findList?name=" + this.name + "&pageSize=15&status=1",
      });
      if (res.data.data) {
        this.toGoodlist = res.data.data.list;
        this.$router.push({
          path: "/goodList",
          query: {
            name: JSON.stringify(this.toGoodlist),
            key: JSON.stringify(this.name),
          },
        });
      }
    },
    toLogin() {
      this.$store.commit("updateshowlogin", true);
    },
    myOrder() {
      const token = sessionStorage.getItem("token");
      console.log(token);
      if (token) {
        this.$router.push("orderList");
      } else {
        this.$message("请登录");
      }
    },
    purchaseOrder() {
      const token = sessionStorage.getItem("token");
      console.log(token);
      if (token) {
        this.$router.push("purchaseOrderList");
      } else {
        this.$message("请登录");
      }
    },
    //公司授权
    // IncUpdate(){
    //    const token = sessionStorage.getItem("token");
    //   console.log(token);
    //   if (token) {
    //     this.$router.push("IncUpdate");
    //   } else {
    //     this.$message("请登录");
    //   }
    //   console.log('公司授权')
    // },
    // applyFor(){
    //    const token = sessionStorage.getItem("token");
    //   console.log(token);
    //   if (token) {
    //     this.$router.push("applyFor");
    //   } else {
    //     this.$message("请登录");
    //   }
     
    // },
    
    goCar() {
      const token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("goodCar");
      } else {
        this.$message("请登录");
      }
    },
    shopList() {
      const token = sessionStorage.getItem("token");
      if (token) {
        this.$router.push("shoppingList");
      } else {
        this.$message("请登录");
      }
    },
  },
  created() {
    this.getshopnum();
  },
  mounted() {
    console.log(JSON.parse(sessionStorage.getItem("userinfo")));
  },
};
</script>
<style lang="scss" scoped>
.talk {
  height: 50px;
  min-width: 100px;
  width: 120px;
  margin-top: 25px;
  margin-right: 8%;
  line-height: 50px;
  cursor: pointer;
  span {
    font-size: 16px;
  }
  .el-icon-chat-dot-round {
    font-size: 25px;
  }
}
.el-header {
  padding: 0;
  display: inline-block;
  /* flex: 1; */
  justify-content: space-around;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
}

.header-box {
  width: 100%;
  height: 30px;
  background-color: #e3e4e5;
}
.header-box-left {
  z-index: 8;
  display: flex;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 30px;
  margin-left: 13%;
  width: 100%;
  position: relative;
  & > div:nth-child(1) {
    user-select: none;
    cursor: pointer;
    margin-right: 100px;
    & > div:nth-child(1) {
      i {
        color: #0ec65d;
        font-size: 16px;
        position: relative;
        top: 2px;
      }
    }
  }
  & > div:nth-child(2) {
    user-select: none;
  }
}

.header-box-login {
  margin-left: 30%;
  cursor: pointer;
  letter-spacing: 1px;
}
.header-box-register {
  margin-left: 2%;
  color: #c61a0e;
  cursor: pointer;
  letter-spacing: 1px;
}
.header-box-list {
  margin-left: 2%;
  /* color: #e33333; */
  cursor: pointer;
}
.header-search {
  display: flex;
  width: 1230px;
  margin: 0 auto;
  justify-content: space-between;
  flex: 1;
}
.avater-logo {
  width: 165px;
  height: 100px;
  margin-left: 0px;
  cursor: pointer;
}
.search-box {
  width: 100%;
  height: 10px;
  display: flex;
  margin-right: 4%;
  margin-left: 4%;
  /* background-color: red; */
}
.el-input {
  width: 81%;
}
.search-box-main {
  display: flex;
  /* width: 30%; */
  margin: auto;
  margin-top: 32px;
}
.search-input {
  width: 500px;
  height: 35px;
  // border: 2px solid #e1251b;
  border-right: 0;
  border-radius: 2px;
  position: relative;
  z-index: 5;
}
.input {
  display: block;
  padding: 0;
  width: 85%;
  height: 100%;
  border: 2px solid #0ec65d;
  position: absolute;
  right: 12.5%;
  z-index: 3;
  text-indent: 5px;
}
.input:focus {
  outline: none;
  /* border: 1px solid red; */
}
.go-search {
  width: 13%;
  height: 100%;
  border: 2px solid #0ec65d;
  background-color: #0ec65d;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.search-icon {
  display: block;
  width: 18px;
  height: 19px;
  margin-left: 38%;
  margin-top: 12%;
}
.header-search .shop-car,.header-search .shop-list {
  min-width: 120px;
  display: flex;
  width: 250px !important;
  height: 32px;
  border: 1px solid #e3e4e5;
  margin: auto;
  margin-right: 2%;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}
.header-search .shop-list .iconfont{
  // color: #0ec65d;
}
.header-search .shop-car .iconfont{
  // color: #0ec65d;
}

.header-search .shop-car span {
  padding-left: 5px;
  line-height: 34px;
}

.shopcar-img {
  width: 28px;
  height: 28px;
  margin-top: 2px;
  margin-left: 3px;
}
.shop-car p {
  display: block;
  font-size: 14px;
  line-height: 35px;
  margin-left: 10px;
  overflow: hidden;
}
.shop-list p {
  display: block;
  font-size: 14px;
  line-height: 35px;
  margin-left: 10px;
  overflow: hidden;
}
.shop-car div {
  width: 30px;
  height: 20px;
  border-radius: 10px;
  background-color: rgb(255, 145, 0);
  margin-top: 7px;
  margin-left: 3px;
}
.shop-cara p {
  font-size: 14px;
  margin: auto;
  text-align: center;
  line-height: 20px;
  font-weight: 800;
  color: white;
}
.iconfont {
  color: #000;
  font-size: 30px;
}
</style>