<template>
  <div class="footer">
    <div class="footer-top">
      <div>
        <i class="el-icon-chat-dot-square"></i>
        <span>预约维修服务</span>
      </div>
      <div>
        <i class="el-icon-time"></i>
        <span>7天无理由退货</span>
      </div>
      <div>
        <i class="el-icon-date"></i>
        <span>15天免费换货</span>
      </div>
      <div>
        <i class="el-icon-truck"></i>
        <span>满99包邮</span>
      </div>
      <div>
        <i class="el-icon-map-location"></i>
        <span>520余家售后网点</span>
      </div>
    </div>
    <!-- footer-bottom -->
    <div class="footer-bottom">
      <div class="message">
      <div>
        <span>帮助中心</span>
        <span>账户管理</span>
        <span>购物指南</span>
        <span>订单操作</span>
      </div>
      <div>
        <span>帮助中心</span>
        <span>账户管理</span>
        <span>购物指南</span>
        <span>订单操作</span>
        <span>购物指南</span>
      </div>
      <div>
        <span>帮助中心</span>
        <span>账户管理</span>
        <span>购物指南</span>
        <span>订单操作</span>
        <span>购物指南</span>
      </div>
      <div>
        <span>帮助中心</span>
        <span>账户管理</span>
        <span>购物指南</span>
        <span>订单操作</span>
      </div>
      <div>
        <span>帮助中心</span>
        <span>账户管理</span>
        <span>购物指南</span>
        <span>订单操作</span>
      </div>
      <div>
        <span>帮助中心</span>
        <span>账户管理</span>
        <span>购物指南</span>
        <span>订单操作</span>
      </div>
      </div>
      <div class="put-on-record">
          <a href="https://beian.miit.gov.cn" target="_blank">豫ICP备16024639号</a>
          <a href="https://cert.jujiangmofang.cn" target="_blank">隐私政策</a>
        </div>
    </div>
    
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 1000px;
  background-color: #f4f4f4;
  .footer-top {
    width: 80%;
    margin: 0 auto;
    padding: 25px;
    display: flex;
    justify-content: space-around;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #86948d;
    div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 25px;
        margin-right: 5px;
      }
    }
    div:not(:last-child) {
      border-right: 1px solid #7b847f;
    }
  }
  .footer-bottom {
  .put-on-record{
    width: 80%;
    margin: auto;
    padding: 10px;
    border-top: 1px solid #86948d;
    text-align: center;
    a{color: #7a7a7a;
    font-size: 12px;
    
    }
    a:hover{color: #1a1a1a;}
    a:not(:last-child){
        border-right: 1px solid #7b847f;
        margin-right: 10px;
        padding:0 10px 0 10px;
    }
  }
    .message{
    width: 80%;
    margin: 0 auto;
    display: flex;
    padding: 20px;
    justify-content: space-around;
    color: #7a7a7a;
    
      div {
      display: flex;
      flex-direction: column;
      span {
        padding: 10px;
      }
      span:first-child {
        margin-bottom: "10px";
        font-size: 20px;
        color: rgb(0, 0, 0);
      }
    }
    }
    
  }
  .footer-bottom span:hover{
    color: rgb(42, 42, 42);}
}

</style>