<template>
  <div class="login">
    <el-main id="e-main">
      <div class="login-box">
        <div class="login-box-top">
          <i class="el-icon-circle-close close" @click="updateshowlogin"></i>
          <span class="login-box-top-txt"
            >欢迎登录园艺联盟,为您提供全方位的服务!</span
          >
        </div>
        <!-- 用户名登陆 -->
        <div class="login-box-input" :data="Form" v-if="loginmethod">
          <div class="login-box-input-username">
            <span>用户名</span>
            <div style="width: 300px; margin-left: 6%; margin-top: 1.5%">
              <el-input
                v-model="Form.phone"
                @input="change()"
                placeholder="请输入用户名"
              ></el-input>
            </div>
          </div>
          <div class="login-box-input-password">
            <span>密码</span>
            <div style="width: 300px; margin-left: 6%; margin-top: 1.5%">
              <el-input
                v-model="Form.password"
                @input="change()"
                placeholder="请输入密码"
                 show-password
              ></el-input>
            </div>
          </div>
          <div class="to-register" @click="loginmethod = !loginmethod">
            手机号登录
          </div>
          <div class="find-password">忘记密码</div>
          
          <div class="go-login" @click="toLogin">
            <span class="go-login-to">登录</span>
            
          </div>
          
          <div class="wxLogin" @click="wxLogin" >微信扫码登录
            
            <div v-show="isShow" id="wxLogin" class="wx" >
             
            </div>
            
          </div>
          <!-- <p class="phonelogin">
            手机号登录
          </p> -->
        </div>
        <!-- 用户名登录结束 -->
        <!-- 验证码登录 -->
        <div v-else>
          <div class="login-box-input" :data="phonelogin">
            <div class="login-box-input-username">
              <span>手机号</span>
              <div style="width: 300px; margin-left: 6%; margin-top: 1.5%">
                <el-input
                  v-model="phonelogin.phone"
                  @input="change()"
                  placeholder="请输入手机号"
                ></el-input>
              </div>
            </div>
            <div class="login-box-input-password">
              <span class="login-box-input-password-code">验证码</span>
              <div style="width: 300px; margin-left: 6%; margin-top: 1.5%">
                <el-input
                  v-model="phonelogin.code"
                  @input="change()"
                  placeholder="请输入验证码"
                ></el-input>
              </div>
            </div>
            <div class="find-password" @click="getcode">获取验证码</div>
            <div class="go-login" @click="tophonelogin">
              <span class="go-login-to">验证码登录</span>
            </div>
            <p class="phonelogin" @click="loginmethod = !loginmethod">
              账号密码登录
            </p>
            
          </div>
        </div>
        <!-- 验证码登录结束 -->
      </div>
    </el-main>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
// export function getCode ({commit},code){
//   return axios({
//     method:'get',
//     url:'front/user/wxAuthorizedLogin',
//     params:{
//       code:code,
//     }
//   }).then(res =>{
//     if(res.resTa && res.Data){
//       commit('UPDATE_USER_INFO',res.resData)
//     }
//   }).catch(e=>{
//     console.log(e)
//   })
// };
export default {
  inject: ["reload"],
  data() {
    return {
      loginmethod: true,
      isShow:false,
      Form: {
        phone: "",
        password: "",
      },
      phonelogin: {
        phone: "",
        code: "",
      },
    };
  },
  watch:{
    $route(to,from){
      console.log(to,from);
      window.location.reload();
    }
  },
  methods: {
    updateshowlogin() {
      console.log(1);
      this.$store.commit("updateshowlogin", false);
    },
    change() {
      this.$forceUpdate();
    },
    //获取权限//公司id
    async getroleId() {
      const res = await this.$http({
        method: "get",
        url: "front/user/getUserByToken",
      });
      if (res.data.data) {
        this.$store.commit("setuserinfo", res.data.data);
        sessionStorage.setItem("userinfo", JSON.stringify(res.data.data));
        sessionStorage.setItem("roleId", res.data.data.roleId);
        sessionStorage.setItem("userId", res.data.data.id);
        const data = await this.$http({
          method: "get",
          url: `front/company/findList?companyUserId=${sessionStorage.getItem(
            "userId"
          )}`,
        });
        if (data.data.status == 200) {
          if (data.data.data.list.length) {
            sessionStorage.setItem("companyId", data.data.data.list[0].id);
          }
          window.location.reload();
        }
      } else {
        sessionStorage.setItem("roleId", "null");
      }
    },
    ...mapMutations(["changeLogin"]),
    async toLogin() {
      const res = await this.$http({
        method: "post",
        url: "front/user/webLogin",
        data: {
          phone: this.Form.phone,
          password: this.Form.password,
        },
      });
      console.log(res);
      if (res.data.status == 200) {
        this.$store.commit("updateshowlogin", false);
        this.$message({
          message: "登录成功",
          type: "success",
        });
        sessionStorage.setItem("token", res.data.data);
        this.getroleId();
      } else {
        this.$message.error("账号或密码错误！");
      }
    },
    //微信扫码登录
    async wxLogin(){
      // const res = await this.$http({
      //   method: "post",
      //   url: "/front/user/wxAuthorizedLogin",
      //   data:{
      //       accessToken:"" ,  //授权码 String
      //       openid: ""   //识别码 String
      //   }
      // });
      // if(res.status == 200){
      //    this.$message({
      //     message: "登录成功",
      //     type: "success",
      //   });
      //   console.log(res)
      //   this.isShow=false
      // }
      this.Qr();
      
      this.isShow = true;
  },
  //获取微信二维码
  async Qr(){
    let obj = await new WxLogin({
      id: "wxLogin",
      self_redirect:false,
      appid: "wx739115f1e223d033", 
      response_type: "code",
      scope: "snsapi_login",
      // 扫码成功后 跳转的地址
      redirect_uri:encodeURIComponent("http://www.jujmf.cn/#/index"),
    });
    
  },
    async tophonelogin() {
      const res = await this.$http({
        method: "post",
        url: `front/user/loginByVerificationCode`,
        data: {
          phone: this.phonelogin.phone,
          verificationCode: this.phonelogin.code,
        },
      });
      if (res.data.status == 200) {
        this.getroleId();
        sessionStorage.setItem("token", res.data.data);
        this.$message({
          message: "注册并登录成功",
          type: "success",
        });
        this.$store.commit("updateshowlogin", false);
      } else {
        this.$message.error("验证码错误！");
      }
    },
    async getcode() {
      const res = await this.$http({
        method: "post",
        url: `front/user/getVerificationCode`,
        data: {
          phone: this.phonelogin.phone,
          verificationCode:this.phonelogin.verificationCode
        },
      });
      if(this.phonelogin.verificationCode == ''){
        this.$message({
          message:'验证码错误',
          type:"error"
        });
        if (res.data.status == 200) {
        this.getroleId();
        sessionStorage.setItem("token", res.data.data);
        this.$message({
          message: "登录成功",
          type: "success",
        });
        console.log(res)
        this.$store.commit("updateshowlogin", false);
      } else {
        this.$message.error("账号或密码错误！");
      }
      } 
    },
  },
  mounted() {
    document.onkeyup = (e) => {
      if (e && e.keyCode == 13 && this.loginmethod) {
        this.toLogin();
      } else if (e && e.keyCode == 13 && !this.loginmethod) {
        this.tophonelogin();
      }
    };
  },
  
};
</script>

<style scoped>
.close {
  position: relative;
  font-size: 25px;
  top: -30px;
  left: 350px;
  cursor: pointer;
  color: #666;
}
#e-main {
  margin: 0;
  padding: 0;
}
.login-box {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 400px;
  background-color: #fff;
  border-radius: 12px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -200px;
  z-index: 10000000000;
}
.login-box-top {
  width: 100%;
  height: 60px;
  margin-top: 38px;
  text-align: center;
}
.login-box-top-txt {
  font-size: 22px;
  line-height: 40px;
  color: #333;
}
.login-box-input {
  width: 100%;
  /* height: 430px; */
  /* margin-top: 10px; */
  /* background-color: violet; */
}
.login-box-input-username {
  display: flex;
  width: 100%;
  height: 50px;
  margin-top: 15px;
  margin-left: -10px;
}
.login-box-input-username span {
  line-height: 50px;
  margin-left: 7%;
  font-size: 12px;
  color: #333;
}
.login-box-input-password {
  display: flex;
  width: 100%;
  height: 50px;
  margin-top: 3px;
  margin-left: 4%;
  margin-left: 2px;
}
.login-box-input-password span {
  line-height: 50px;
  margin-left: 7%;
  font-size: 12px;
  color: #333;
}
.to-register {
  float: left;
  margin-top: 14px;
  margin-left: 21%;
  font-size: 12px;
  line-height: 25px;
  color: #333;
}
.find-password {
  font-size: 12px;
  margin-left: 66%;
  margin-top: 15px;
  line-height: 25px;
  color: #333;
  user-select: none;
  cursor: pointer;
}
.go-login {
  width: 60%;
  height: 40px;
  background-color: #cbae61;
  text-align: center;
  margin: 0 auto;
  margin-top: 5%;
}
.wxLogin{
 
  color: #999;
  text-align: center;
  margin-top: 40px;
}
.wx{
    width: 450px;
    height: 384px;
    background-color: #fff;
    border-radius: 12px;
    position: absolute;
    top: 60%;
    left: 54%;
    margin-left: -243px;
    margin-top: -202px;
    z-index: 10000000000;
    text-align: center;
}
.back{
  position: absolute;
  left: 8%;
  top: 4%;
}
.wx::before{
  width: 20px;
  height: 20px;
  background: #333;
}
.go-login-to {
  font-size: 17px;
  color: #fff;
  line-height: 40px;
  cursor: pointer;
}
.logo {
  width: 150px;
  height: 70px;
}
.logo-box {
  float: left;
  width: 150px;
  height: 55px;
  margin-left: 14%;
  margin-top: 4px;
  border-radius: 4px;
  cursor: pointer;
}
.phonelogin {
  color: #333;
  margin-top: 40px;
  text-align: center;
  cursor: pointer;
}
.login-box-input-password > .login-box-input-password-code {
  margin-left: 20px;
}
</style>