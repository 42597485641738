<template>
  <div id="app" ref="app" :style="{ position: 'relative' }">
    <el-backtop target="#app" :bottom="70"> </el-backtop>
    <div class='saoma'>
          <el-button style="padding:7px" round class="saoma1" size="mini" @click="$router.push('/downloadapp')" >
          <span style="color:#0095ff">下载<br>app</span></el-button>
    </div>
    <!-- <transition :name="transitionName"> -->
    <keep-alive>
      <router-view v-if="isRouterAlive && $route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- </transition> -->
 
    <!-- <transition :name="transitionName"> -->
    <router-view v-if="!$route.meta.keepAlive && isRouterAlive"></router-view>
    <!-- </transition> -->

    <login v-if="showlogin" />
    <my-footer v-if="!$route.meta.footer"></my-footer>
  </div>
</template>

<script>
import login from "./views/login";
import MyFooter from "./components/my-footer.vue";

export default {
  components: { login, MyFooter },
  name: "App",
  data() {
    return {
      isRouterAlive: true,
      transitionName: "", 
    };
  },
  computed: {
    showlogin() {
      return this.$store.state.showlogin;
    },
  },
  watch: {
    $route(to, from) {
      this.$refs.app.scrollTo(0, 0);
      //此时假设从index页面跳转到pointList页面
      this.transitionName = "";
      // console.log(this.$router);
      // console.log(to); // "/pointList"
      // console.log(from); // “/index”
      let routeDeep = ["/"];
      if (routeDeep.indexOf(to.path) != -1) {
        delete routeDeep[to.path];
      }

      const toDepth = routeDeep.indexOf(to.path);
      routeDeep.push(to.path);
      const fromDepth = routeDeep.indexOf(from.path);
      console.log(!to.meta.hasOwnProperty("transition"));
      if (!to.meta.hasOwnProperty("transition")) {
        console.log(to.path);
        this.transitionName = toDepth > fromDepth ? "fold-right" : "fold-right";
      }
    },
  },

  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    async ready() {
      // var cip = returnCitySN["cip"];
      // console.log(cip)
      const res = await this.$http({
        method: "get",
        url: `baidu/location/ip`,
        params: {
          ak: "u4FH7X1ixpIyRmjGu6XrQh4LxvtXkAtK",
          // ip: cip,
          coor: "bd09ll",
        },
        
      });
      
      if (res.data.status == 0) {
        const data = await this.$http({
          method: "get",
          url: `baidu/reverse_geocoding/v3/?ak=u4FH7X1ixpIyRmjGu6XrQh4LxvtXkAtK&output=json&coordtype=wgs84ll&location=${res.data.content.point.y},${res.data.content.point.x}`,
        });
        console.log(data.data,'定位位置')
        if (data.data.status == 0) {
          sessionStorage.setItem(
            "address",
            JSON.stringify(data.data.result.formatted_address)
          );
          sessionStorage.setItem(
            "city",
            JSON.stringify(data.data.result.addressComponent)
          );
        }
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    async getallAreaData() {
      const res = await this.$http({
        method: "get",
        url: "front/area/allAreaData",
      });
      var bbb = JSON.parse(
        JSON.stringify(res.data.data).replace(/areaId/g, "value")
      );
      var bbb = JSON.parse(JSON.stringify(bbb).replace(/areaName/g, "label"));
      var bbb = JSON.parse(
        JSON.stringify(bbb).replace(/childList/g, "children")
      );
      var bbb = JSON.parse(
        JSON.stringify(bbb).replace(/\,"children"\:\[\]/g, "")
      );
      sessionStorage.setItem("allAreaData", JSON.stringify(bbb));
      let allAreaData = JSON.parse(sessionStorage.getItem("allAreaData"));
      let city = JSON.parse(sessionStorage.getItem("city"));
      allAreaData.forEach((v, k) => {
        if (v.value == Number(city.adcode.slice(0,2))) {
          // console.log(v,"位置",city)
          sessionStorage.setItem("addressProvince", v.value);
          v.children.forEach((v, k) => {
            if (v.value == Number(city.adcode.slice(0,4))) {
              sessionStorage.setItem("addressCity", v.value);
              sessionStorage.setItem("checked", JSON.stringify(["", v.label]));
              console.log(v,"位置",city)
              sessionStorage.setItem("checkeds", JSON.stringify(["", v.label]));
              this.$store.commit(
                "updatechecked",
                JSON.stringify(["", v.label])
              );
              this.$store.commit(
                "updatecheckeds",
                JSON.stringify(["", v.label])
              );
            }
          });
        }
      });
       
          
        
     
    },
  },
  created() {
    this.getallAreaData();
  },
  mounted() {
    this.ready();
  },
};
</script>

<style lang='scss'>
.fold-left-enter-active {
  animation-name: fold-left-in;
  animation-duration: 0.3s;
}
.fold-left-leave-active {
  animation-name: fold-left-out;
  animation-duration: 0.3s;
}
.fold-right-enter-active {
  animation-name: fold-left-out;
  animation-duration: 0.3s;
}
.fold-right-leave-active {
  animation-name: fold-left-in;
  animation-duration: 0.3s;
}
@keyframes fold-left-in {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes fold-left-out {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}

#app {
  width: 100%;
  /*核心  */
  height: 100vh;
  /* 核心 */
  overflow-x: hidden;

  position: relative;
}
ol,
ul {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
}
ol,
ul {
  list-style: none;
}
.el-table th.gutter {
  display: table-cell !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}
@font-face {
  font-family: "iconfont";
  src: url("../src/assets/iconfont/font_1971396_wwfngkei0hs/iconfont.eot");
  src: url("../src/assets/iconfont/font_1971396_wwfngkei0hs/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/iconfont/font_1971396_wwfngkei0hs/iconfont.woff2")
      format("woff2"),
    url("../src/assets/iconfont/font_1971396_wwfngkei0hs/iconfont.woff")
      format("woff"),
    url("../src/assets/iconfont/font_1971396_wwfngkei0hs/iconfont.ttf")
      format("truetype"),
    url("../src/assets/iconfont/font_1971396_wwfngkei0hs/iconfont.svg#iconfont")
      format("svg");
}
.el-button--default > span {
  color: #606266 !important;
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 30px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.saoma{
  position:fixed;
  right: 40px;
  bottom: 120px;
  z-index: 5;
  bottom:120px;
  .saoma1{
    box-shadow: rgba(0, 0, 0,12%) 0px 0px 6px};
  
}
</style>
